exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-call-confirmed-tsx": () => import("./../../../src/pages/call-confirmed.tsx" /* webpackChunkName: "component---src-pages-call-confirmed-tsx" */),
  "component---src-pages-cdap-getting-started-tsx": () => import("./../../../src/pages/cdap-getting-started.tsx" /* webpackChunkName: "component---src-pages-cdap-getting-started-tsx" */),
  "component---src-pages-cdap-tsx": () => import("./../../../src/pages/cdap.tsx" /* webpackChunkName: "component---src-pages-cdap-tsx" */),
  "component---src-pages-client-onboarding-form-tsx": () => import("./../../../src/pages/client-onboarding-form.tsx" /* webpackChunkName: "component---src-pages-client-onboarding-form-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-e-commerce-accounting-tsx": () => import("./../../../src/pages/e-commerce-accounting.tsx" /* webpackChunkName: "component---src-pages-e-commerce-accounting-tsx" */),
  "component---src-pages-saas-companies-tsx": () => import("./../../../src/pages/saas-companies.tsx" /* webpackChunkName: "component---src-pages-saas-companies-tsx" */),
  "component---src-pages-signup-confirmation-tsx": () => import("./../../../src/pages/signup-confirmation.tsx" /* webpackChunkName: "component---src-pages-signup-confirmation-tsx" */),
  "component---src-pages-thank-you-tsx": () => import("./../../../src/pages/thank-you.tsx" /* webpackChunkName: "component---src-pages-thank-you-tsx" */),
  "component---src-pages-why-us-tsx": () => import("./../../../src/pages/why--us.tsx" /* webpackChunkName: "component---src-pages-why-us-tsx" */),
  "component---src-templates-archive-tsx": () => import("./../../../src/templates/archive.tsx" /* webpackChunkName: "component---src-templates-archive-tsx" */),
  "component---src-templates-types-page-tsx": () => import("./../../../src/templates/types/page.tsx" /* webpackChunkName: "component---src-templates-types-page-tsx" */),
  "component---src-templates-types-post-tsx": () => import("./../../../src/templates/types/post.tsx" /* webpackChunkName: "component---src-templates-types-post-tsx" */)
}

