/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import './src/css/custom.css';
import './src/css/index.css';
import '@wordpress/block-library/build-style/style.css';
import '@fontsource/quattrocento-sans';

import Provider from './src/models/Root';

export const wrapRootElement = Provider;
